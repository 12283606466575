html {
  scroll-behavior: smooth;
}

body {
  background-color: #FBF5E9 !important;
  background-size: cover;
  margin: 0;
  padding : 0;
}
/* Navbar Css */

.custom-navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease; /* Smooth transition for background color, box shadow, and opacity */
  background-color: #A5A8AE;
  padding: 0!important;
  opacity: 1; /* Default opacity */
}

/* Navbar CSS */
.custom-navbar {
  position: sticky; /* Makes the navbar sticky */
  top: 0;
  z-index: 1000; /* Ensure it stays on top of other content */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease; /* Smooth transition for background color, box shadow, and opacity */
  background-color: #A5A8AE;
  padding: 0!important;
  opacity: 1; /* Default opacity */
}

.custom-navbar.scrolled {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
/* Optional: Add fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.navbar-toggler {
  border: initial !important;
}

.navbar-collapse {
  justify-content: flex-end;
  padding: 0; /* Remove default padding */
}

.navbar-nav {
  display: flex;
  flex-direction: column; /* Stack links vertically */
  align-items: flex-end;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  font-weight:500;
  font-size:18px;
}

.navbar-link {
  color: #f0f0f0;
  margin: 5px 0; /* Reduce vertical margin */
  padding: 5px 10px; /* Reduce padding around links */
  text-decoration: none;
  /* font-weight: bold; */
  /* font-size: 18px; /* Adjust font size 
  font-weight: 500; */
}

.navbar-link.active {
  font-weight: bold;
  color: #007bff;
}

/* Optional: Customize hover and active states */
.navbar-link:hover {
  color: whitesmoke;
  text-decoration: underline;
}

.navbar-link.active {
  color: whitesmoke;
  font-weight: bold;
  text-decoration: underline;
}

.logo {
  height: 50px;
  width: auto;
}

/* Adjust navbar-toggler to take less space */
.navbar-toggler {
  padding: 0.25rem 0.75rem; /* Reduce padding around the toggler */
  font-size: 1rem; /* Adjust font size */
}

.navbar-toggler-icon {
  width: 1.5em; /* Reduce the size of the icon */
  height: 1.5em; /* Ensure icon height is also reduced */
}


.legacy-link{
  color: #84582f!important;
  font-weight: bold !important;
}
.work-link{
  color: #008080!important;
  font-weight: bold !important;
}
.play-link{
  color: #FF8000!important;
  font-weight:Bold!important;
}
/* Navbar CSS */
.custom-navbar {
  position: sticky; /* Keeps the navbar sticky */
  top: 0;
  z-index: 1000; /* Ensure it stays on top of other content */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease; /* Smooth transition for background color, box shadow, and opacity */
  background-color: #A5A8AE; /* Solid color at the top */
  padding: 0!important;
  opacity: 1; /* Default opacity */
}

.custom-navbar.scrolled {
  background-color: rgba(165, 168, 174, 0.8); /* Transparent background with 80% opacity */
  backdrop-filter: blur(10px); /* Adds a blur effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  opacity: 0.95; /* Slightly reduce opacity for a glass-like effect */
}
/*Custom offCanvas */ 
.custom-offcanvas{
  color: #004c4c!important;
}

/*news-ticker CSS */

/* Ensure the ticker container has enough space */
.news-ticker-container-wrapper {
  display: flex;
  align-items: center;
  background-color: #d9d9d9; /* Light background */
  border: 1px solid #84582F; /* Border color */
  padding: 0px;
  overflow: hidden;
  width: 100%; /* Ensure the container takes full width */
}

.news-ticker-container {
  display: flex;
  align-items: center;
  background-color: #d9d9d9; /* New background color for margin */
  padding: 0;
  white-space: nowrap; /* Ensure the content stays in a single line */
}

.news-label {
  background-color: #84582F; /* Background color */
  color: white;
  padding: 1px 16px;
  font-weight: bold;
  margin-right: 2px;
}

.news-ticker {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.news-ticker span {
  display: inline-block;
  padding-left: 100%;
  animation: scroll 70s linear infinite; /* Adjust timing as needed */
}

/* Keyframes for scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/*Shield Component CSS */ 
img[alt="Shield"]{
  width:600px;
}
.shield-image {
  margin-top: 50px;
  max-width:70%!important;
  max-height: 90%;
}
.text-box {
  background-color: #f5f5f5!important; /* Light gray background */
  max-width: 600px; /* Adjust width as needed */
  font-size: 18px;
}

.custom-button {
  background-color: #8B5E34!important; /* Brown color matching the screenshot */
  border: none!important;
  padding: 10px 20px;
  border-radius: 5px;
  font-size:20px!important;
}

.custom-button-2{
  margin-top:10px;
  background-color: #8B5E34!important;
  border: none !important;
}

.custom-button:hover {
  background-color: #724d2c!important; /* Darker brown on hover */
}

/* Responsive CSS */
/*Tablet Css */
@media only screen and (max-width: 768px){
  .shield-image{
   margin-top:100px;
  }
  .news-ticker span {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 85s linear infinite; /* Adjust timing as needed */
  }

} 
@media only screen and (max-width: 600px){
  .shield-image{
  margin:0 auto;
  }
  .news-ticker span {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 85s linear infinite; /* Adjust timing as needed */
  }
}


.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container > *:nth-last-child(2) {
  flex: 1;
}

.text-row {
  margin-bottom: 20px!important;
}
.shield-container{
  margin-bottom: 20px;
}
.footer-text{
  padding-top:10px;
  font-size:14px!important
}
.offices-text{
  padding-top: 10px !important;
  font-size:14px!important;
}
.get-invited-link{
  color: 'white';
  text-decoration: none;
}